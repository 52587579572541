/* src/components/common/navbar/Navbar.css */
.navbar {
  position: fixed;
    z-index: 1000; 
    display: flex;
    justify-content: center; 
    align-items: center;
    width: 100%;
    padding: 10px 0px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    
  }
  
  .navbar-logo {
    height: 50px; /* Juster størrelsen etter behov */

  }
  
  .logout-icon {
     font-size: 24px; /* Størrelse på ikonet */
    margin-left: 20px; /* Plasserer ikonet helt til høyre */
    cursor: pointer;
    color: #ffffff; /* Farge på ikonet, endre etter behov */
  }
  
  .logout-icon:hover {
    color: #d4d4d4;
  }
  .menu-icon {
    position: absolute;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
    color: #ffffff;
  }
  
  .menu-icon:hover {
    color: #141414;
  }
 

.menu-icon {
    position: absolute;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
    color: #000000;
    top: 23px;
  }
  
  .menu-icon:hover {
    color: #d4d4d4;
}

