.myProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

  }
  .profile {
    padding-top: 100px;
  }
  .profile-container {
    display: flex;
    max-width: 600px;
    background-color: #fff;
    align-items: center;

    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .profile-container h1 {
    color: #007bff;
  }
  
  .user-info p {
    color: #666;
    margin-bottom: 10px;
  }
  
  .profile-form {
    margin-top: 20px;
  }
  
  .profile-form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .profile-form input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  button {

    padding: 10px;
    background-color: #007bff;
    color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

.delete-btn {
  background-color: #dc3545;
  margin-top: 20px;
}

.delete-btn:hover {
  background-color: #c82333;
}

.profile-form button, .delete-btn {
  font-size: 16px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .profile-container {

    margin-top: 10px;
  }
}
input {
    padding: 10px;
}
.profile-header {
    padding: 20px;
}