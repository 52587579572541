/* changeOwner.css */
.changeOwner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .new-owner-input, .change-owner-btn {
    margin: 10px 0;
    padding: 10px;
    width: 80%;
    box-sizing: border-box; /* Sørger for at input og knapp ikke overstiger containerbredden */
  }
  
  .change-owner-btn {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  