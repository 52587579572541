.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to bottom, #fff 0%, #f0f4f8 100%);
  color: #333;
  font-family: 'Roboto', sans-serif;
}



.login-banner {
  width: 100%;
  height: 40%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.login-player-image {
  max-height: 100%;
  width: auto;
  opacity: 0.8;
}

.login-container {
  width: 100%;
  max-width: 400px;
  margin: -100px auto 30px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 10;
}

.login-logo {
  width: 200px;
  margin-bottom: 20px;
}

.login-header {
  margin-bottom: 10px;
  font-size: 2rem;
  color: #102a43;
}

.login-description {
  margin-bottom: 30px;
  color: #334e68;
}
.login-description1 {
  margin-bottom: 30px;
  color: #9aa1a7;
}

.action-button {
  display: block;
  width: 80%;
  padding: 15px;
  margin: 10px auto;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 25px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.action-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}


.auth-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to bottom, #fff 0%, #f0f4f8 100%);
  color: #333;
  font-family: 'Roboto', sans-serif;
}

.auth-container {
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.auth-header {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #102a43;
}

.auth-form input {
  width: calc(100% - 30px);
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.auth-form button {
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.auth-form button:hover {
  background-color: #0056b3;
}

.auth-form .action-link {
  display: block;
  margin-top: 25px;
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s ease;
}
.action-link {
  display: block;
  margin-top: 25px;
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s ease;
}

.auth-form .action-link:hover {
  color: #0056b3;
}

/* Du kan legge til mer stil her for spesifikke justeringer på hver side */
@media (max-width: 768px) {


  .login-player-image {
    max-height: 80%;
  }

  .login-container {
    margin-top: -120px;
    width: 70%;
  }

  .action-button {
    width: 90%;
  }
  .auth-container {
    width: 70%;
  }
}
.privacy-policy {
  font-size: 14px;
  color: #666;
  margin-top: 20px;
}
.auth-error {
  color: #dc3545;
  margin-bottom: 20px;
}
.auth-message {
  color: #28a745;
  margin-bottom: 20px;
}