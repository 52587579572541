.my-purchases-page {

  
    font-family: 'Arial', sans-serif;
    padding-bottom: 100px; 
  }
  
  .purchase-date-group + .purchase-date-group {
    margin-top: 40px;
  }
  
  .purchase-date {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }
  
  .purchases-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
  }
  
  .purchase-card {
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
    border: 1px solid #e0e0e0; /* Light grey border */
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .purchase-card p {
    margin: 0 0 10px 0;
    color: #666;
    font-size: 16px;
  }
  
  .purchase-card p:last-child {
    margin-bottom: 0;
  }
  
  .purchase-container {
    padding: 20px;
    padding-top: 100px;

  }