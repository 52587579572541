/* bottomBar.css */
.bottom-bar {
    display: sticky;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 45px; /* Øker høyden på baren */
    background-color: #075198;
    box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
    z-index: 1000;
    padding-bottom: 25px;
    padding-top: 10px;
}

.bottom-bar a {
    color: #ffffff;
    font-size: 24px;
    text-decoration: none; /* Fjerner understreking fra linker */
}

.icon {
    color: white;
}
.bottom-bar {
    display: none; /* Skjuler baren på skjermer større enn 768px */

}

@media (max-width: 768px) {
    .bottom-bar {
        display: flex; /* Sørger for at baren kun vises på skjermer mindre enn 768px */
    }
}

