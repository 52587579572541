/* src/pages/HomePage/HomePage.css */
.homepage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Fyll hele skjermhøyden */
  background: linear-gradient(to bottom, #fff 0%, #075198 100%);
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.splashscreen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  max-width: 300px; /* Juster etter behov */
  margin-bottom: 30px;
}

.loading-circle {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top-color: #ffffff; /* Hvit farge på toppen */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.under-text {
  color: #ffffff; /* Hvit tekstfarge */
  font-size: 0.8rem;
  font-weight: bold;
}
.over-text {
  color: #ffffff; /* Hvit tekstfarge */
  font-size: 1rem;
  font-weight: bold;
}