/* slideMenu.css */
.slide-menu {
    position: fixed;
    top: 0;
    right: -100%; /* Start utenfor skjermen */
    width: 400px; /* Juster bredde etter behov */
    height: 100%;
    background-color: #ffffff;
    box-shadow: -2px 0 5px rgba(0,0,0,0.2); /* Skygge på venstre side */
    transition: right 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-top: 60px; /* Juster dette for å unngå overlapp med eventuell navbar */
    z-index: 999;

  }
  
  .slide-menu.open {
    right: 0; /* Når menyen er åpen, flytt den inn i visningen */
  }
  
  .slide-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-top: 1px solid #e0e0e0; /* Skillestrek på toppen av listen */
    padding: 20px;
    
  }
  
  .slide-menu ul li {
    padding: 15px 20px;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0; /* Skillestreker mellom elementene */
    transition: background-color 0.2s ease; /* Glatt overgang for hover-effekt */

  }
  
  .slide-menu ul li:hover {
    background-color: #f5f5f5; /* Endre farge på hover */
  }
  
  /* Gjør tekst større og mer leselig */
  .slide-menu ul li {
    font-size: 25px; /* Større tekst */
    color: #333; /* Mørkere tekstfarge for bedre lesbarhet */
  }
  /* slideMenu.css - Fortsettelse fra eksisterende stilsett */

  .app-info {
    padding: 20px; /* Gir litt pusterom rundt teksten */
    text-align: center; /* Sentrerer teksten */
    font-size: 14px; /* Mindre tekststørrelse for info */
    color: #666; /* Dempet tekstfarge */
    border-top: 1px solid #e0e0e0; /* Skillestrek over app-info */
  }
  
  .app-info p {
    margin: 5px 0; /* Lite mellomrom mellom paragrafene */
  }

@media (max-width: 768px) {
  .slide-menu {
    width: 100%; /* Juster bredden for mindre skjermer */
  }
}
  