/* Dashboard.css */
body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f0f2f5;
  }

  .dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-bottom: 100px; 

  }
  .welcome-message {
    text-align: center;

  }
  .welcome-message h1 {
    font-size: 24px;
    color: #333;
    margin: 0;
    padding-bottom: 10px;
  }
  .welcome-message p {
    font-size: 16px;
    color: #666;
    margin: 0;
    padding-bottom: 20px;
  }
  .section {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;

  }
  .section h2 {
    font-size: 20px;
    color: #007bff;
    margin: 0 0 20px 0;
  }
  .card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin: 10px 0;
  }
  .card h3 {
    margin: 0 0 10px 0;
    color: #333;
  }
  .buy-tickets {
    text-align: center;
    padding: 20px;
    background-color: #fff; /* En attraktiv bakgrunnsfarge */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  .buy-tickets .or-text {
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px;
  }
  .buy-tickets button {
    font-size: 18px;
    padding: 15px 30px;
    background-color: #315eb8;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .buy-tickets button:hover {
    background-color: #1e7a38;
  }
  .login-logo-bottom {
    max-height: 50px; /* Juster etter behov */
    margin-bottom: 30px;
  }
  @media (max-width: 768px) {

  
    .section, .buy-tickets {
      padding: 20px 10px 20px;
    }
  }
  @media (max-width: 768px) {
    .section {
      background-color: transparent; /* Setter section til gjennomsiktig */
      box-shadow: none; /* Fjerner skygge for et renere utseende */
    }

  }
  .purchase-success {
    color: #28a745;
    background-color: #dff0d8;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
  }
  .card {
    transition: all 0.3s ease;
    cursor: pointer;
    overflow: hidden; /* For å sikre at animasjonen for utvidelse er smooth */
  }
  .card:hover {
    transform: scale(1.02); /* Gjør kortet litt større ved hover */
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card-details {
    max-height: 0;
    transition: max-height 0.3s ease;
  }
  .card:hover .card-details {
    max-height: 500px; /* Juster etter behov basert på innhold */
  }
  .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  .card-content h3, .card-content p {
    margin: 0; /* Juster etter behov */
  }
  .qr-code {
    margin-top: 10px; /* Juster etter behov for spacing rundt QR-koden */
    display: grid;
    justify-content: center; /* Sentrerer QR-koden i kortet */
  }
  .containerr-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .welcome {
    padding-top: 100px;

  }
  /* Legg til dine eksisterende CSS-stiler her */

/* Stil for det nye inputfeltet og bekreftelsesknappen */
