.map-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .map-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
  }
  
  .venue-map {
    max-width: 90%; /* Adjusts the image width to fit the screen */
    max-height: 70vh; /* Adjusts the image height to be at most 70% of the viewport height */
    margin-bottom: 20px; /* Adds some space below the image */
    border-radius: 8px; /* Optionally rounds the corners of the image */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for depth */
  }
  
  .back-to-dashboard-btn {
    background-color: #007bff; /* A pleasant blue */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .back-to-dashboard-btn:hover {
    background-color: #0056b3; /* A slightly darker blue for the hover state */
  }
  
  /* You can add this if you want a fixed Navbar and BottomBar */
  .navbar, .bottombar {
    position: fixed;
    width: 100%;
    z-index: 100;
  }
  
  .navbar {
    top: 0;
  }
  
  .bottombar {
    bottom: 0;
  }
  