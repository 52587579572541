/* TicketPurchase.css */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f0f2f5;
}
.ticket {
  padding-top: 100px;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 100px; 
  margin-top: 60px;

}
.ticket-purchase-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    display: flexbox;
    padding: 60px;
  }
  
  .ticket-purchase-header {
    text-align: center;
    color: #0056b3;
    margin-bottom: 30px;
  }
  
  .ticket-purchase-header h1 {
    margin: 0;
    padding: 0;
    font-size: 24px;
  }
  
  .ticket-purchase-header p {
    font-size: 18px;
    color: #333;
  }
  
  .ticket-purchase-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;

  }
  
  .ticket-purchase-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  .ticket-purchase-form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #cccccc;
    background-color: #f9f9f9;
    font-size: 16px;
    color: #333;
  }
  
  .purchase-button {
    grid-column: 1 / -1;
    padding: 12px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.2s;
  }
  
  .purchase-button:hover {
    background-color: #218838;
  }
  .back-button {
    display: block;
    margin: 1rem auto;
    padding: 0.5rem 1rem;
    background-color: #f0f2f5;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
  
    /* Plasser knappen sentralt over ticket-purchase-container */
    position: relative;
    top: 1rem;


  }
  
  .back-button:hover {
    background-color: #e2e6ea;
    border-color: #adb5bd;
  }
  

/* TicketPurchase.css */

/* ... other styles ... */

.card-element-container {
  margin-top: 20px; /* Adjust the margin as needed */
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  grid-column: 1 / -1;

  
}

/* Style for the Stripe CardElement, this class is automatically attached to the CardElement iframe */
.StripeElement {

  padding: 15px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #ced4da;
}

.StripeElement--focus {
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.total-sum {
  grid-column: 1 / -1;
  margin-bottom: 20px;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg);
  }}
  .error-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }